<template>
    <ts-panel>
        <ts-panel-wrapper class="tw-space-y-2">
            <div class="row">
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("agencyProfile.userName")
                    }}</label>
                    <input
                        type="text"
                        v-model="model.user_name"
                        class="form-control"
                        :class="{ 'is-invalid': errors.has('user_name') }"
                        :placeholder="$t('agencyProfile.userName')"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('user_name')"
                    >
                        {{ errors.first("user_name") }}
                    </div>
                </div>
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("agencyProfile.password")
                    }}</label>
                    <input
                        type="password"
                        v-model="model.password"
                        class="form-control"
                        :placeholder="$t('agencyProfile.password')"
                        :class="{ 'is-invalid': errors.has('password') }"
                    />
                    <div class="invalid-feedback" v-if="errors.has('password')">
                        {{ errors.first("password") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{
                        $t("agencyProfile.confirm")
                    }}</label>
                    <input
                        type="password"
                        v-model="model.password_confirmation"
                        class="form-control"
                        :class="{
                            'is-invalid': errors.has('password_confirmation')
                        }"
                        :placeholder="$t('agencyProfile.confirm')"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('password_confirmation')"
                    >
                        {{ errors.first("password_confirmation") }}
                    </div>
                </div>
            </div>
        </ts-panel-wrapper>
    </ts-panel>
</template>

<script>
import { Errors } from "form-backend-validation";
export default {
    name: "agencyUser",
    props: ["value", "validate"],
    data() {
        return {
            model: {},
            errors: new Errors()
        };
    },
    created() {
        this.model = this.value;
    },
    watch: {
        validate: function(error) {
            this.errors = new Errors(error.errors);
        }
    }
};
</script>
